<template>
  <div class="card">
    <header class="card-header">
      <div class="columns is-multiline card-header-title ">
        <div class="column is-12">
          <p class="modal-card-title">
            PRODUCTOS RELACIONADOS
          </p>
        </div>
      </div>
    </header>
    <div class="card-content">
      <div class="columns is-multiline mb-6">
        <div class="column is-12">
          <p class="paragraph has-text-weight-bold">Agregar productos</p>
        </div>
        <div class="column is-6">
          <div class="select slct is-fullwidth">
            <select v-model="productTemporalId" name="lineId">
              <option selected disabled :value="null">Selecciona un producto</option>
              <option v-for="products in guidesProductOptions" :key="products.id" :value="products.id">
                {{ products.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="column is-3">
          <button class="button button_micro_dark_outlined is-fullwidth" @click="addProductSelected">
            AGREGAR
          </button>
        </div>
      </div>
      <div :class="[ relatedProductsSelect.length === empty ? 'block' : 'content' ]">
        <template>
          <div
            class="media is-align-items-center mr-5"
            :class="{ 'media_border_bottom' : modeEdit }"
            v-for="(relatedProduct, indexProduct ) in relatedProductsSelect" :key="relatedProduct.id"
          >
            <div class="media-left">
              <figure class="image is-64x64">
                <img :src="`${ASSETS_URL}${relatedProduct.main_image}`" :alt="relatedProduct.name">
              </figure>
            </div>
            <div class="media-content">
              <p class="text_18 has-text-weight-bold text_green_dark"> {{ relatedProduct.name }} </p>
            </div>
            <div class="media-right" v-if="modeEdit">
              <img class="icon_delete" src="@/assets/icon/trash_icon.png" alt="Borrar" @click="deleteProduct(indexProduct)">
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardAddRelatedProducts',
  inject: ['$validator'],
  data () {
    return {
      empty: 0,
      modeEdit: true,
      ASSETS_URL: process.env.VUE_APP_URL_FILES,
      relatedProductsSelect: [],
      productTemporalId: null
    }
  },
  props: {
    guidesProductOptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    // Agregar producto relacionado
    addProductSelected () {
      if (this.productTemporalId) {
        const productDataSelect = this.guidesProductOptions.find(product => product.id === this.productTemporalId)
        // Validamos que no exista el objeto
        const productExist = this.relatedProductsSelect.findIndex(product => product.id === productDataSelect.id)
        const doesNotExist = -1
        if (productExist === doesNotExist) {
          this.relatedProductsSelect.push(productDataSelect)
        }
        this.productTemporalId = null
      }
    },
    deleteProduct (indexProduct) {
      this.relatedProductsSelect.splice(indexProduct, 1)
    },
    getDataProducts () {
      return this.relatedProductsSelect
    }
  }
}
</script>

<style lang="scss" scoped>
  .card {
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: none;
  }
  .card-header {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    border-bottom: solid 1px #e8e8e8;
  }

  .block {
    min-height: 130px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .content {
    height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }

  .content figure {
    .image {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .button_micro_dark_outlined {
    border: solid 2px #262626;
  }

  .media + .media {
    border-top: none;
  }
  .media_border_bottom + .media_border_bottom {
    border-top: 1px solid #e8e8e880;
  }

  .icon_delete {
    cursor: pointer;
    width: 22px;
  }
</style>
